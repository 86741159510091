import React from 'react';

export const OffersSkeleton: React.FC = () => (
  <div className="[&_.skeleton]:bg-action-subtle flex animate-pulse flex-col items-start gap-2">
    <div className="skeleton h-4 w-full" />
    <div className="skeleton h-3 w-2/3" />
    <div className="skeleton h-3 w-2/3" />
    <div className="skeleton h-3 w-2/3" />
  </div>
);
