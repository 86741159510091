import React from 'react';
import { Box } from '@mui/material';
import { AphDrawer } from '@aph/components/common';
import { ConsentInfo } from '@aph/components/common/gdpr/consent-info/consent-info.component';
import { useConsentsStore } from '~/model/gdpr-consent/use-consents-store';
import {
  useMemberConsentActions,
  useMemberConsentStore,
} from '~/model/gdpr-consent/use-member-consent-store';
import { PanelType } from '~/model/panels/panels.panel-type';
import { usePanel } from '~/model/panels/usePanel';
import { MyInfoConsents } from './my-info-consents.component';

export const MyInfoConsentsContainer: React.FC = () => {
  const { memberConsents } = useMemberConsentStore();
  const { saveMemberConsents, resetSubmittEventConsent } = useMemberConsentActions();
  const { consents } = useConsentsStore();
  const moreInfoConsentsPersonalOffers = usePanel(
    PanelType.MORE_INFO_GDPR_CONSENTS_PERSONAL_OFFERS,
  );
  const moreInfoConsentsBonus = usePanel(PanelType.MORE_INFO_GDPR_CONSENTS_BONUS);
  const moreInfoConsentsRetailMedia = usePanel(PanelType.MORE_INFO_GDPR_CONSENTS_RETAIL_MEDIA);

  const openDrawer = (consentType: string) => {
    switch (consentType) {
      case 'Bonus':
        moreInfoConsentsBonus.setPanelActive();
        break;
      case 'PersonalOffer':
        moreInfoConsentsPersonalOffers.setPanelActive();
        break;
      case 'RetailMedia':
        moreInfoConsentsRetailMedia.setPanelActive();
        break;
      default:
        break;
    }
  };

  return (
    <Box data-testid="TAB.CONTENT.CONSENTS">
      <MyInfoConsents
        memberConsents={memberConsents}
        consents={consents}
        saveMemberConsents={saveMemberConsents}
        resetSubmittEventForConsent={resetSubmittEventConsent}
        openMoreInfo={openDrawer}
      />
      <AphDrawer
        isPanelActive={moreInfoConsentsPersonalOffers.isPanelActive}
        onCloseHandler={moreInfoConsentsPersonalOffers.setPanelInactive}
        variant="wide"
        mobileVariant="wide"
        mobileAnchor="right"
      >
        <ConsentInfo
          onClose={moreInfoConsentsPersonalOffers.setPanelInactive}
          consentType="PersonalOffers"
        />
      </AphDrawer>
      <AphDrawer
        isPanelActive={moreInfoConsentsBonus.isPanelActive}
        onCloseHandler={moreInfoConsentsBonus.setPanelInactive}
        variant="wide"
        mobileVariant="wide"
        mobileAnchor="right"
      >
        <ConsentInfo onClose={moreInfoConsentsBonus.setPanelInactive} consentType="Bonus" />
      </AphDrawer>
      <AphDrawer
        isPanelActive={moreInfoConsentsRetailMedia.isPanelActive}
        onCloseHandler={moreInfoConsentsRetailMedia.setPanelInactive}
        variant="wide"
        mobileVariant="wide"
        mobileAnchor="right"
      >
        <ConsentInfo
          onClose={moreInfoConsentsRetailMedia.setPanelInactive}
          consentType="RetailMedia"
        />
      </AphDrawer>
    </Box>
  );
};
