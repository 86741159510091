import type { IntlShape } from 'react-intl';
import { type ErrorIntlKey, type ErrorMessage, isErrorIntlKey, isErrorMessage } from './types';

export function localizedErrorMessage(
  error: ErrorMessage | ErrorIntlKey | null | undefined,
  intl: IntlShape,
): string | null {
  if (error) {
    if (isErrorIntlKey(error)) {
      return intl.messages[error.errorIntlKey]?.toString() ?? 'Något gick fel!';
    }
    if (isErrorMessage(error)) {
      return error.errorMessage;
    }
  }

  return null;
}
