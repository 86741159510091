/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MemberInfoNames } from '../member-info.schema';
import { MemberInput } from '../member-input/member-input.component';

export type MemberDataInputProps = {
  streetName?: string;
  zipCode?: number;
  mobileNumber?: string;
  email?: string;
  city?: string;
};

export const MemberDataInput: React.FC<MemberDataInputProps> = ({
  streetName,
  zipCode,
  mobileNumber,
  email,
  city,
}) => {
  const intl = useIntl();

  const { register, formState } = useFormContext();

  const { errors } = formState;

  const { ref: streetNameRef, ...streetNameProps } = register(MemberInfoNames.STREET_NAME);
  const { ref: zipCodeRef, ...zipCodeProps } = register(MemberInfoNames.ZIP_CODE);
  const { ref: cityRef, ...cityProps } = register(MemberInfoNames.CITY);
  const { ref: emailRef, ...emailProps } = register(MemberInfoNames.EMAIL);
  const { ref: emailConfirmRef, ...emailConfirmProps } = register(MemberInfoNames.EMAILCONFIRM);
  const { ref: mobileNumberRef, ...mobileNumberProps } = register(MemberInfoNames.MOBILENUMBER);

  return (
    <div>
      {/* StreetName input */}
      <MemberInput
        id="COMMON.FORM_ELEMENTS.INPUT_STREET_NAME.LABEL"
        label={intl.formatMessage({ id: 'COMMON.FORM_ELEMENTS.INPUT_STREET_NAME.LABEL' })}
        inputRef={streetNameRef}
        error={!!errors.streetName}
        defaultValue={streetName}
        helperText={
          errors.streetName
            ? intl.formatMessage({ id: errors.streetName.message?.toString() || '' }) ||
              errors.streetName.message?.toString()
            : null
        }
        className="mb-2"
        data-testid="MY.INFO.STREET.NAME"
        {...streetNameProps}
      />

      <div className="flex flex-col sm:flex-row sm:justify-between">
        {/* Postal Code input */}
        <MemberInput
          id="COMMON.FORM_ELEMENTS.INPUT_POSTAL_CODE.LABEL"
          label={intl.formatMessage({ id: 'COMMON.FORM_ELEMENTS.INPUT_POSTAL_CODE.LABEL' })}
          inputRef={zipCodeRef}
          error={!!errors.zipCode}
          defaultValue={zipCode}
          helperText={
            errors.zipCode
              ? intl.formatMessage({ id: errors.zipCode.message?.toString() || '' }) ||
                errors.zipCode.message?.toString()
              : null
          }
          className="mb-2 w-full sm:w-[49%]"
          data-testid="MY.INFO.POSTAL.CODE"
          {...zipCodeProps}
        />

        {/* City input */}
        <MemberInput
          id="COMMON.FORM_ELEMENTS.INPUT_CITY.LABEL"
          label={intl.formatMessage({ id: 'COMMON.FORM_ELEMENTS.INPUT_CITY.LABEL' })}
          inputRef={cityRef}
          error={!!errors.city}
          defaultValue={city}
          helperText={
            errors.city
              ? intl.formatMessage({ id: errors.city.message?.toString() || '' }) ||
                errors.city.message?.toString()
              : null
          }
          className="mb-2 w-full sm:w-[49%]"
          data-testid="MY.INFO.CITY"
          {...cityProps}
        />
      </div>
      <div className="flex flex-col sm:flex-row sm:justify-between">
        {/* Email input */}
        <MemberInput
          id="COMMON.FORM_ELEMENTS.INPUT_EMAIL.LABEL"
          label={intl.formatMessage({ id: 'COMMON.FORM_ELEMENTS.INPUT_EMAIL.LABEL' })}
          inputRef={emailRef}
          error={!!errors.email}
          defaultValue={email}
          helperText={
            errors.email
              ? intl.formatMessage({ id: errors.email.message?.toString() || '' }) ||
                errors.email.message?.toString()
              : null
          }
          className="mb-2 w-full sm:w-[49%]"
          data-testid="MY.INFO.EMAIL"
          {...emailProps}
        />

        {/* Email confirm input */}
        <MemberInput
          id="COMMON.FORM_ELEMENTS.INPUT_EMAIL_CONFIRM.LABEL"
          label={intl.formatMessage({ id: 'COMMON.FORM_ELEMENTS.INPUT_EMAIL_CONFIRM.LABEL' })}
          inputRef={emailConfirmRef}
          error={!!errors.emailConfirm}
          defaultValue={email}
          helperText={
            errors.emailConfirm
              ? intl.formatMessage({ id: errors.emailConfirm.message?.toString() || '' })
              : null
          }
          className="mb-2 w-full sm:w-[49%]"
          data-testid="MY.INFO.EMAIL.CONFIRM"
          {...emailConfirmProps}
        />
      </div>
      {/* Mobile number input */}
      <MemberInput
        id="COMMON.FORM_ELEMENTS.INPUT_MOBILENUMBER.LABEL"
        label={intl.formatMessage({ id: 'COMMON.FORM_ELEMENTS.INPUT_MOBILENUMBER.LABEL' })}
        inputRef={mobileNumberRef}
        error={!!errors.mobileNumber}
        defaultValue={mobileNumber}
        helperText={
          errors.mobileNumber
            ? intl.formatMessage({ id: errors.mobileNumber.message?.toString() || '' }) ||
              errors.mobileNumber.message?.toString()
            : null
        }
        data-testid="MY.INFO.MOBILENUMBER"
        {...mobileNumberProps}
      />
    </div>
  );
};
