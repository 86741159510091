export type ErrorIntlKey = Record<string, unknown> & Record<'errorIntlKey', string>;
export type ErrorMessage = Record<string, unknown> & Record<'errorMessage', string>;
export type ErrorCode = Record<string, unknown> & Record<'errorCode', string>;

export interface RedirectData {
  isRedirect: true;
  location: string;
}

function isRecord(obj: unknown): obj is Record<string, unknown> {
  return typeof obj === 'object' && obj !== null;
}

export function isErrorIntlKey(obj: unknown): obj is ErrorIntlKey {
  return isRecord(obj) && 'errorIntlKey' in obj;
}

export function isErrorMessage(obj: unknown): obj is ErrorMessage {
  return isRecord(obj) && 'errorMessage' in obj;
}

export function isRedirectData(data: unknown): data is RedirectData {
  return Boolean(isRecord(data) && data.isRedirect);
}
