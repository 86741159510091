import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { logger } from '~/logging';
import { type CreateMemberParams, createMember } from './create-member/create-member.api';
import { type MemberState, initialState } from './member-state';
import { getMemberForMe } from './member.api';
import { type UpdateMemberParams, updateMember } from './update-member/update-member.api';
import { getErrorOrDefault } from './utils';

interface MemberStore {
  member: MemberState;
  actions: {
    getMemberDataForMe: () => void;
    updateMemberData: ({ body }: UpdateMemberParams) => void;
    createMemberData: ({ body }: CreateMemberParams) => void;
    resetSubmitEvent: () => void;
  };
}

export const useMemberStore = create<MemberStore>()(
  devtools<MemberStore>(
    (set, get) => ({
      member: initialState,
      actions: {
        getMemberDataForMe: async () => {
          const correlationId = uuidv4();
          set(
            {
              member: {
                ...initialState,
                loading: true,
                error: null,
                updated: Date.now(),
                fetchFailed: null,
              },
            },
            false,
            'Fetching member',
          );
          try {
            const response = await getMemberForMe(correlationId);
            logger.info(
              `getMemberDataForMe, Correlation ID: ${correlationId} was fecthed successfully`,
            );
            set(
              {
                member: {
                  ...initialState,
                  data: response,
                  loading: false,
                  success: true,
                  error: null,
                  updated: Date.now(),
                  fetchFailed: false,
                },
              },
              false,
              'Fetching member success',
            );
          } catch (event) {
            const { status, httpStatusCode } = event as { status: string; httpStatusCode: number };
            const error = getErrorOrDefault(event, 'MEMBER_DATA_FOR_ME.FAILED_TO_FETCH');

            logger.error(
              `getMemberDataForMe, Correlation ID: ${correlationId} failed to fetch | errorMessage: ${
                error.errorMessage
              } | error: ${JSON.stringify(
                error,
              )} | status: ${status} | httpStatusCode: ${httpStatusCode}`,
            );

            set(
              {
                member: {
                  ...initialState,
                  loading: false,
                  success: false,
                  error: error || initialState.error,
                  updated: Date.now(),
                  fetchFailed: true,
                },
              },
              false,
              'Fetching member failed',
            );
          }
        },
        updateMemberData: async (body) => {
          const correlationId = uuidv4();
          const memberState = get().member;
          set(
            {
              member: {
                ...memberState,
                loading: false,
                error: null,
                updated: Date.now(),
                submitting: true,
                success: null,
              },
            },
            false,
            'Updating member',
          );
          try {
            const response = await updateMember(body, correlationId);
            logger.info(`updateMemberData, Correlation ID: ${correlationId} was successfully`);
            set(
              {
                member: {
                  ...memberState,
                  data: response.memberData || memberState.data,
                  error: null,
                  loading: false,
                  updated: Date.now(),
                  submitting: false,
                  success: true,
                },
              },
              false,
              'Updating member success',
            );
          } catch (event) {
            const { status, httpStatusCode } = event as { status: string; httpStatusCode: number };
            const error = getErrorOrDefault(event, 'MEMBER_DATA.FAILED_TO_UPDATE');

            logger.error(
              `updateMemberData, Correlation ID: ${correlationId} failed to fetch | errorMessage: ${
                error.errorMessage
              } | error: ${JSON.stringify(
                error,
              )} | status: ${status} | httpStatusCode: ${httpStatusCode}`,
            );

            set(
              {
                member: {
                  ...memberState,
                  loading: false,
                  error: error || memberState.error,
                  updated: Date.now(),
                  submitting: false,
                  success: false,
                },
              },
              false,
              'Updating member failed',
            );
          }
        },
        createMemberData: async (body) => {
          const correlationId = uuidv4();
          set(
            {
              member: {
                ...initialState,
                loading: true,
                error: null,
                updated: Date.now(),
                submitting: true,
              },
            },
            false,
            'Creating member',
          );
          try {
            const response = await createMember(body, correlationId);
            logger.info(`createMemberData, Correlation ID: ${correlationId} was successfully`);
            set(
              {
                member: {
                  ...initialState,
                  data: response.memberData || initialState.data,
                  error: null,
                  loading: false,
                  updated: Date.now(),
                  submitting: false,
                  success: true,
                },
              },
              false,
              'Creating member success',
            );
          } catch (event) {
            const { status, httpStatusCode } = event as { status: string; httpStatusCode: number };
            const error = getErrorOrDefault(event, 'MEMBER_DATA.FAILED_TO_CREATE_MEMBER');

            logger.error(
              `createMemberData, Correlation ID: ${correlationId} failed to fetch | errorMessage: ${
                error.errorMessage
              } | error: ${JSON.stringify(
                error,
              )} | status: ${status} | httpStatusCode: ${httpStatusCode}`,
            );

            set(
              {
                member: {
                  ...initialState,
                  loading: false,
                  error: error || initialState.error,
                  updated: Date.now(),
                  submitting: false,
                  success: false,
                },
              },
              false,
              'Creating member failed',
            );
          }
        },
        resetSubmitEvent: () => {
          const memberState = get().member;
          set(
            {
              member: {
                ...memberState,
                success: null,
              },
            },
            false,
            'Reset submit event member',
          );
        },
      },
    }),
    {
      name: 'UseMember',
      anonymousActionType: 'UseMember',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useMemberActions = () => useMemberStore((state) => state.actions);
