import theme from '~/styles/theme';

export const classes = {
  tabs: {
    backgroundColor: theme.palette['color/background/visual'],
    borderRadius: 24,
    display: 'flex',
    alignItems: 'center',
    minHeight: 40,
    height: 40,
    '& button.Mui-selected': {
      fontSize: 14,
      fontWeight: 700,
      backgroundColor: theme.palette['color/background/elevated'],
      color: theme.palette['color/text/action-dark'],
    },
  },
  activeLargeTab: {
    minHeight: 48,
    height: 48,
    '& button.Mui-selected': {
      fontSize: 16,
    },
  },
  activeSmallTab: {
    minHeight: 32,
    height: 32,
    '& button.Mui-selected': {
      fontSize: 12,
    },
  },
  tab: {
    margin: theme.spacing(0, 0.25),
    borderRadius: 24,
    minHeight: 36,
    height: 36,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette['color/text/default'],
    '&.Mui-focusVisible': {
      outline: `2px solid ${theme.palette['color/border/action-dark']}`,
      outlineOffset: theme.spacing(-0.25),
    },
  },
  largeTab: {
    fontSize: 16,
    minHeight: 44,
    height: 44,
  },
  smallTab: {
    fontSize: 12,
    minHeight: 28,
    height: 28,
  },
};
