import type { ErrorCode, ErrorIntlKey, ErrorMessage } from '@aph/utilities/errors/types';
import type { MemberDataV3 } from '~/services/generated/CustomerClient';

export type MemberState = {
  data: MemberDataV3 | null;
  error: ErrorIntlKey | (ErrorMessage & ErrorCode) | null;
  loading: boolean;
  updated: number;
  submitting: boolean | null;
  success: boolean | null;
  fetchFailed: boolean | null; // needed to make decisions UI wise
};

export const initialState: MemberState = {
  data: null,
  error: null,
  loading: false,
  updated: 0,
  submitting: null,
  success: null,
  fetchFailed: null,
};
