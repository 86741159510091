import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import type { MappedMemberConsent } from '@aph/components/common';
import {
  GdprConsent,
  GdprConsentSkeleton,
  SubmitButtonWithStates,
  mapMemberConsentsToConsent,
  mapSelectedOptionsToMemberConsents,
} from '@aph/components/common';
import { AphAlert } from '@aph/components/common/aph-alert/aph-alert.component';
import type { ConsentFormValues } from '@aph/components/common/gdpr/gdpr-consent/handle-consents';
import { Typography } from '@aph/ui/components/typography/typography';
import type { ConsentsState } from '~/model/gdpr-consent/consent-state';
import type { MemberConsentsState } from '~/model/gdpr-consent/use-member-consent-state';
import type { IMemberConsentV3 } from '~/services/generated/CustomerClient';

type MyInfoConsentsProps = {
  memberConsents: MemberConsentsState;
  consents: ConsentsState;
  resetSubmittEventForConsent: () => void;
  saveMemberConsents: (payload: IMemberConsentV3[]) => void;
  openMoreInfo: (consentType: string) => void;
};

export const MyInfoConsents: React.FC<MyInfoConsentsProps> = ({
  memberConsents,
  consents,
  resetSubmittEventForConsent,
  saveMemberConsents,
  openMoreInfo,
}) => {
  const intl = useIntl();

  const formStateConsent = useForm<ConsentFormValues>();
  const [mappedConsents, setMappedConsents] = useState<MappedMemberConsent[]>([]);

  const { consents: consentsData } = consents.data;
  const { error: consentsError } = consents;
  const { consents: memberConsentsData } = memberConsents.data;
  const {
    success: memberConsentsSuccess,
    submitting: memberConsentsSubmitting,
    fetchFailed,
    error: memberConsentsError,
  } = memberConsents;
  const failedToSaveMessage = { message: intl.messages['CONSENT.SAVE.ERROR.MESSAGE'].toString() };

  useEffect(() => {
    setMappedConsents(mapMemberConsentsToConsent(consentsData, memberConsentsData));
  }, [memberConsentsData, consentsData]);

  const onSubmitConsents: SubmitHandler<ConsentFormValues> = (selectedConsentOptions) => {
    if (memberConsentsData) {
      saveMemberConsents(
        mapSelectedOptionsToMemberConsents(memberConsentsData, selectedConsentOptions),
      );
    }
  };

  return (
    <>
      <Typography typography="headingExtraLarge" className="mb-1 pt-3" color="text-brand" asChild>
        <h1>
          <FormattedMessage id="CLUB_MEMBER.CONSENT.TITLE" />
        </h1>
      </Typography>
      <Box width={{ xs: '100%', sm: '454px' }}>
        <AphAlert data-pw="my-consent-info-alert" severity="info">
          <Typography>
            OBS! Inom klubb Hjärtat hanterar vi aldrig personuppgifter om receptbelagda läkemedel
          </Typography>
        </AphAlert>
      </Box>
      {(fetchFailed || consentsError) && (
        <AphAlert
          data-pw="my-consent-info-alert"
          severity="error"
          variant="filled"
          title={intl.formatMessage({ id: 'ERROR.GENERAL' })}
          data-testid="ERROR.ALERT"
        />
      )}
      {memberConsentsData &&
        memberConsentsData.length > 0 &&
        consentsData &&
        consentsData?.length > 0 && (
          <FormProvider {...formStateConsent}>
            <form onSubmit={formStateConsent.handleSubmit(onSubmitConsents)}>
              {mappedConsents.length > 0 ? (
                mappedConsents.map(
                  (consent) =>
                    consent.code && (
                      <GdprConsent
                        key={consent.code}
                        consentType={consent.code}
                        options={consent.options}
                        isChecked={consent.consentOptionCode}
                        onOpenMoreInfo={openMoreInfo}
                      />
                    ),
                )
              ) : (
                <>
                  <GdprConsentSkeleton />
                  <GdprConsentSkeleton />
                </>
              )}

              {Object.keys(formStateConsent.formState.errors).length > 0 && (
                <Typography
                  color="text-error"
                  className="my-1 flex flex-col items-center sm:items-end"
                  data-testid="CONSENT.ERROR.MESSAGE"
                >
                  <FormattedMessage id="CONSENT.ERROR.MESSAGE" />
                </Typography>
              )}
              <SubmitButtonWithStates
                className="mb-3 items-start md:w-auto md:items-end lg:mb-0"
                buttonText={intl.formatMessage({ id: 'CLUB_MEMBER.CONSENT.SAVE' })}
                successButtonText={intl.formatMessage({ id: 'CLUB_MEMBER.CONSENT.SAVED' })}
                submitting={memberConsentsSubmitting}
                success={memberConsentsSuccess}
                error={!fetchFailed && memberConsentsError ? failedToSaveMessage : null}
                afterSuccessAction={() => {
                  resetSubmittEventForConsent();
                }}
                afterSuccessActionDelay={
                  process.env.NEXT_PUBLIC_DELAY_AFTER_SUCCESSFUL_FORM_SUBMIT as unknown as number
                }
              />
            </form>
          </FormProvider>
        )}
    </>
  );
};
