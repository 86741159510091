import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, Skeleton, Stack } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { AphAlert } from '@aph/components/common/aph-alert/aph-alert.component';
import { Typography } from '@aph/ui/components/typography/typography';
import {
  usePharmaciesActions,
  usePharmaciesStore,
} from '~/model/pharmacy/pharmacies/use-pharmacies-store';
import type { Pharmacy } from '~/services/generated/PharmacyClient';
import { MemberInfoNames } from '../member-info.schema';
import type { CityOption } from './pharmacy-select';
import { buildPharmacyCityModel } from './pharmacy-select';

export type PharmacySelectProps = {
  selectedIcaId?: number;
};

export const PharmacySelect: React.FC<PharmacySelectProps> = ({ selectedIcaId }) => {
  const { getPharmacies } = usePharmaciesActions();
  const { pharmacies } = usePharmaciesStore();
  const [cities, setCities] = useState<CityOption[] | null>(null);

  const [selectedCity, setSelectedCity] = React.useState<CityOption>({
    value: '',
    displayText: '',
    pharmacies: [],
  });
  const [selectedPharmacy, setSelectedPharmacy] = React.useState<Pharmacy | undefined>();
  const intl = useIntl();

  const {
    register,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (pharmacies && pharmacies.updated === 0) {
      getPharmacies();
    }
    if (pharmacies && pharmacies.data && pharmacies.data.length > 0 && cities === null) {
      setCities(
        buildPharmacyCityModel(
          pharmacies.data,
          selectedIcaId,
          setSelectedCity,
          setSelectedPharmacy,
          setValue,
        ),
      );
    }
  }, [
    pharmacies,
    cities,
    selectedIcaId,
    setSelectedCity,
    setSelectedPharmacy,
    selectedCity,
    selectedPharmacy,
    setValue,
    getPharmacies,
  ]);

  const changeCity = (city: CityOption) => {
    setSelectedCity(city);
    setSelectedPharmacy(undefined);
    setValue(MemberInfoNames.BMS_STORE_ID_OF_PREFERRED_PHARMACY, '');
  };

  const loadingSkeleton = () => (
    <>
      <Skeleton variant="rectangular" style={{ height: 50, width: 312, marginBottom: 16 }} />
      <Skeleton variant="rectangular" style={{ height: 50, width: 312, marginBottom: 16 }} />
    </>
  );

  const { ref, ...pharmacyProps } = register(MemberInfoNames.BMS_STORE_ID_OF_PREFERRED_PHARMACY);

  return (
    <Stack marginTop={2} marginBottom={2} direction="column">
      <Typography typography="headingMedium" className="mb-1" asChild>
        <h2>
          <FormattedMessage id="CLUB_MEMBER.SELECT.PHARMACY.TITLE" />
        </h2>
      </Typography>
      {pharmacies.error && (
        <AphAlert
          data-pw="pharmacy-select-alert"
          severity="error"
          variant="filled"
          title={intl.formatMessage({ id: 'CLUB_MEMBER.SELECT.PHARMACY.FETCHERROR' })}
          data-testid="ERROR.ALERT"
        />
      )}
      {pharmacies.loading && loadingSkeleton()}
      {!pharmacies.loading && cities && (
        <>
          <FormControl
            variant="filled"
            sx={{
              margin: (theme) => theme.spacing(1),
              minWidth: 120,
              maxWidth: 324,
              marginLeft: 0,
            }}
          >
            <Autocomplete
              value={selectedCity || ({ value: '', displayText: '', pharmacies: [] } as CityOption)}
              id="select-city"
              onChange={(_e, city) => {
                if (city) {
                  changeCity(city);
                }
              }}
              disableClearable
              isOptionEqualToValue={(option, value) =>
                option.value === value.value || value.value === ''
              }
              getOptionLabel={(option) => option.displayText}
              options={cities}
              noOptionsText=""
              data-testid="PHARMACY.SELECT.CITY"
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.bmsStoreIdOfPreferredPharmacy && selectedCity?.value === ''}
                  variant="filled"
                  label={intl.formatMessage({
                    id: 'CLUB_MEMBER.SELECT.PHARMACY.CHOOSE_CITY_LABEL',
                  })}
                  size="small"
                />
              )}
            />
          </FormControl>
          <FormControl
            variant="filled"
            sx={{
              margin: (theme) => theme.spacing(1),
              minWidth: 120,
              maxWidth: 324,
              marginLeft: 0,
            }}
          >
            <Controller
              control={control}
              {...pharmacyProps}
              defaultValue={selectedPharmacy?.icaId || ''}
              render={({ field }) => (
                <>
                  <Autocomplete
                    value={selectedPharmacy}
                    disabled={selectedCity.value === ''}
                    disableClearable
                    onChange={(e, pharmacy) => {
                      if (pharmacy) {
                        setSelectedPharmacy(pharmacy);
                        setValue(
                          MemberInfoNames.BMS_STORE_ID_OF_PREFERRED_PHARMACY,
                          pharmacy.icaId,
                        );
                        clearErrors(MemberInfoNames.BMS_STORE_ID_OF_PREFERRED_PHARMACY);
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      value && (option.icaId === value.icaId || value.icaId === '')
                    }
                    id="select-pharmacy"
                    getOptionLabel={(option) => option.name || ''}
                    options={(selectedCity && selectedCity.pharmacies) || []}
                    noOptionsText=""
                    ref={ref}
                    data-testid="PHARMACY.SELECT.PHARMACY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.bmsStoreIdOfPreferredPharmacy}
                        variant="filled"
                        label={intl.formatMessage({
                          id: 'CLUB_MEMBER.SELECT.PHARMACY.CHOOSE_PHARMACY_LABEL',
                        })}
                        size="small"
                        name={field.name}
                      />
                    )}
                  />
                  {errors.bmsStoreIdOfPreferredPharmacy?.message && (
                    <FormHelperText>
                      {intl.formatMessage({
                        id: errors.bmsStoreIdOfPreferredPharmacy.message?.toString(),
                      }) || ''}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </FormControl>
        </>
      )}
    </Stack>
  );
};
