import React from 'react';

export const MemberDataInputSkeleton: React.FC = () => (
  <div className="[&_.skeleton]:bg-action-subtle grid h-auto w-full animate-pulse grid-cols-2 flex-col gap-2 pb-2">
    <div className="skeleton col-span-2 h-9 w-full sm:col-span-1" />
    <div className="col-span-2 grid grid-cols-1 gap-2 sm:grid-cols-2">
      <div className="skeleton h-9 w-full" />
      <div className="skeleton h-9 w-full" />
      <div className="skeleton h-9 w-full" />
      <div className="skeleton h-9 w-full" />
    </div>
    <div className="skeleton col-span-2 h-9 w-full sm:col-span-1" />
  </div>
);
