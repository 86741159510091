import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import { MemberCheckbox } from '../member-checkbox/member-checkbox.component';
import { MemberInfoNames } from '../member-info.schema';

export type OffersProps = {
  wantsEmail: boolean;
  wantsMail: boolean;
  wantsSms: boolean;
};

export const Offers: React.FC<OffersProps> = ({ wantsEmail, wantsMail, wantsSms }) => {
  const intl = useIntl();

  const { register } = useFormContext();

  const { ref: byEmailRef, ...byEmailProps } = register(MemberInfoNames.WANTS_OFFERS_BY_EMAIL);
  const { ref: byMailRef, ...byMailProps } = register(MemberInfoNames.WANTS_OFFERS_BY_MAIL);
  const { ref: bySmsRef, ...bySmsProps } = register(MemberInfoNames.WANTS_OFFERS_BY_SMS);

  return (
    <div className="flex flex-col gap-1">
      <Typography>
        <FormattedMessage id="CLUB_MEMBER.OFFER.TEXT" />
      </Typography>
      <div className="flex w-fit flex-col gap-1 pb-0.5 pl-0.5">
        <MemberCheckbox
          {...byEmailProps}
          id="CLUB_MEMBER.OFFER.EMAIL.CHECKBOX"
          ref={byEmailRef}
          data-testid="CLUB_MEMBER.OFFER.EMAIL"
          defaultChecked={wantsEmail}
        >
          {intl.formatMessage({ id: 'CLUB_MEMBER.OFFER.EMAIL' })}
        </MemberCheckbox>
        <MemberCheckbox
          {...bySmsProps}
          id="CLUB_MEMBER.OFFER.SMS.CHECKBOX"
          ref={bySmsRef}
          data-testid="CLUB_MEMBER.OFFER.SMS"
          defaultChecked={wantsSms}
        >
          {intl.formatMessage({ id: 'CLUB_MEMBER.OFFER.SMS' })}
        </MemberCheckbox>
        <MemberCheckbox
          {...byMailProps}
          id="CLUB_MEMBER.OFFER.MAIL.CHECKBOX"
          ref={byMailRef}
          data-testid="CLUB_MEMBER.OFFER.MAIL"
          defaultChecked={wantsMail}
        >
          {intl.formatMessage({ id: 'CLUB_MEMBER.OFFER.MAIL' })}
        </MemberCheckbox>
      </div>
    </div>
  );
};
