import { container } from 'tsyringe';
import type { Pharmacy } from '~/services/generated/PharmacyClient';
import { Client as PharmacyClient } from '~/services/generated/PharmacyClient';

/* eslint-disable import/prefer-default-export */
export const getActivePharmacies = async (): Promise<Pharmacy[]> => {
  const client = container.resolve(PharmacyClient);
  const response = await client.getPharmacies(client.uuid, 'aphTinman');
  return response;
};
