import type { HtmlHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { Icon } from '@aph/ui/components/icon/icon';
import { cn } from '@aph/ui/tailwind/cn';

export const MemberCheckbox = forwardRef<HTMLInputElement, HtmlHTMLAttributes<HTMLInputElement>>(
  ({ id, children, className, ...props }, ref) => (
    <label className={cn('flex cursor-pointer select-none gap-1', className)} htmlFor={id}>
      <div className="mt-0.25 relative h-[18px] w-[18px] shrink-0">
        <input
          id={id}
          ref={ref}
          type="checkbox"
          className="border-action-darkest peer h-full w-full appearance-none rounded-sm border-2 focus:outline-none focus:ring-0"
          {...props}
        />
        <span className="bg-action-darkest absolute left-1/2 top-1/2 h-full w-full origin-center -translate-x-1/2 -translate-y-1/2 scale-0 rounded-full opacity-0 transition-all peer-focus-visible:scale-150 peer-focus-visible:opacity-30" />
        <Icon
          name="Tick"
          className="fill-inverse bg-action-darkest absolute left-1/2 top-1/2 h-[16px] w-[16px] origin-center -translate-x-1/2 -translate-y-1/2 scale-0 rounded-sm opacity-0 transition-all peer-checked:scale-100 peer-checked:opacity-100"
        />
      </div>
      {children}
    </label>
  ),
);
