import React from 'react';
import { useMemberActions, useMemberStore } from '~/model/member/use-member-store';
import { MyInfoPersonal } from './my-info-personal.component';

export const MyInfoPersonalContainer: React.FC = () => {
  const { member } = useMemberStore();
  const { resetSubmitEvent, updateMemberData } = useMemberActions();

  return (
    <MyInfoPersonal
      member={member}
      resetSubmitEvent={resetSubmitEvent}
      updateMemberData={updateMemberData}
    />
  );
};
