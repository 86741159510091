import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { localizedErrorMessage } from '@aph/utilities/errors/errorLocalization';
import type { ErrorCode, ErrorIntlKey, ErrorMessage } from '@aph/utilities/errors/types';
import { isEmailError, isMobileNumberError, isZipCodeError } from '../handleErrorMessageFromServer';
import { MemberInfoNames } from '../member-info.schema';
import { MemberDataInput } from './member-data-input.component';

type MemberDataInputContainerProps = {
  streetName?: string;
  zipCode?: number;
  mobileNumber?: string;
  email?: string;
  city?: string;
  memberError: ErrorIntlKey | (ErrorMessage & ErrorCode) | null;
};

export const MemberDataInputContainer: React.FC<MemberDataInputContainerProps> = ({
  streetName,
  zipCode,
  mobileNumber,
  email,
  memberError,
  city,
}) => {
  const {
    formState: { errors },
    setError,
  } = useFormContext();

  const intl = useIntl();

  useEffect(() => {
    if (memberError) {
      const hasMobileError = isMobileNumberError(memberError);
      const hasZipCodeError = isZipCodeError(memberError);
      const hasEmailError = isEmailError(memberError);
      if (hasMobileError) {
        setError(MemberInfoNames.MOBILENUMBER, {
          type: 'manual',
          message: localizedErrorMessage(memberError, intl) || undefined,
        });
      }

      if (hasZipCodeError) {
        setError(MemberInfoNames.ZIP_CODE, {
          type: 'manual',
          message: localizedErrorMessage(memberError, intl) || undefined,
        });
      }

      if (hasEmailError) {
        setError(MemberInfoNames.EMAIL, {
          type: 'manual',
          message: localizedErrorMessage(memberError, intl) || undefined,
        });
      }
    }
  }, [errors, intl, memberError, setError]);

  return (
    <MemberDataInput
      streetName={streetName}
      zipCode={zipCode}
      mobileNumber={mobileNumber}
      email={email}
      city={city}
    />
  );
};
