import React from 'react';
import { Offers } from './offers.component';

type OffersContainerProps = {
  wantsEmail: boolean;
  wantsMail: boolean;
  wantsSms: boolean;
};

export const OffersContainer: React.FC<OffersContainerProps> = ({
  wantsEmail,
  wantsMail,
  wantsSms,
}) => <Offers wantsEmail={wantsEmail} wantsMail={wantsMail} wantsSms={wantsSms} />;
