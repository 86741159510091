import type { Dispatch, SetStateAction } from 'react';
import type { FieldValues, UseFormSetValue } from 'react-hook-form';
import type { Pharmacy } from '~/services/generated/PharmacyClient';
import { MemberInfoNames } from '../member-info.schema';

const citySorter = (a: CityOption, b: CityOption): 1 | -1 | 0 => {
  if (a.value > b.value) {
    return 1;
  }

  if (a.value < b.value) {
    return -1;
  }

  return 0;
};

export const buildPharmacyCityModel = (
  pharmacies: Pharmacy[],
  icaId: number | undefined,
  setSelectedCity: Dispatch<SetStateAction<CityOption>>,
  setSelectedPharmacy: Dispatch<SetStateAction<Pharmacy | undefined>>,
  setValue: UseFormSetValue<FieldValues>,
): CityOption[] => {
  if (!pharmacies) {
    return [];
  }

  const cities: CityOption[] = [];
  pharmacies.forEach((pharmacy) => {
    if (!pharmacy.icaId || pharmacy.icaId.trim() === '') {
      return;
    }

    let cityName = 'Ej satt';

    if (pharmacy.visitingAddress && pharmacy.visitingAddress.postal) {
      cityName = pharmacy.visitingAddress.postal;
    }

    let city: CityOption;

    const foundCityIndex = cities.findIndex((c) => c.value === cityName);

    if (foundCityIndex === -1) {
      city = {
        value: cityName,
        displayText: cityName,
        pharmacies: [],
      } as CityOption;

      cities.push(city);
    } else {
      city = cities[foundCityIndex];
    }

    city.pharmacies.push(pharmacy);

    if (icaId && icaId.toString() === pharmacy.icaId) {
      setSelectedCity(city);
      setSelectedPharmacy(pharmacy);
      setValue(MemberInfoNames.BMS_STORE_ID_OF_PREFERRED_PHARMACY, pharmacy.icaId);
    }

    cities.sort(citySorter);
  });

  return cities;
};

export type CityOption = {
  value: string;
  displayText: string;
  pharmacies: Pharmacy[];
};
