import type { ErrorCode, ErrorIntlKey, ErrorMessage } from '@aph/utilities/errors/types';

export const isMobileNumberError = (
  error: ErrorIntlKey | (ErrorMessage & ErrorCode) | null,
): boolean | null =>
  error &&
  (error.errorCode === 'PhoneInUse' ||
    error.errorCode === 'NotSwedishPhone' ||
    error.errorCode === 'InvalidAreaCode' ||
    error.errorCode === 'InvalidPhoneNumberLength' ||
    error.errorCode === 'InvalidPhone');

export const isZipCodeError = (
  error: ErrorIntlKey | (ErrorMessage & ErrorCode) | null,
): boolean | null => error && error.errorCode === 'InvalidZip';

export const isEmailError = (
  error: ErrorIntlKey | (ErrorMessage & ErrorCode) | null,
): boolean | null => error && error.errorCode === 'EmailInUse';
