import type { Pharmacy } from '~/services/generated/PharmacyClient';

export interface PharmaciesState {
  data: Pharmacy[];
  error: boolean;
  errorMessage: string | null;
  loading: boolean;
  updated: number;
  success: boolean | null;
}

export const initialState: PharmaciesState = {
  data: [],
  error: false,
  errorMessage: null,
  loading: false,
  updated: 0,
  success: null,
};
