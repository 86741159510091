import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MemberCheckbox } from '../member-checkbox/member-checkbox.component';
import { MemberInfoNames } from '../member-info.schema';

export type CustomerPanelProps = {
  isSelected: boolean;
};

export const CustomerPanel: React.FC<CustomerPanelProps> = ({ isSelected }) => {
  const intl = useIntl();

  const { register } = useFormContext();

  const { ref, ...customerPanelsProps } = register(
    MemberInfoNames.WANTS_TO_TAKE_PART_OF_CUSTOMER_PANELS,
  );

  return (
    <div className="flex flex-col gap-1">
      <MemberCheckbox
        {...customerPanelsProps}
        id="CLUB_MEMBER.CUSTOMER_PANEL.CHECKBOX"
        ref={ref}
        data-testid="CUSTOMER.PANEL"
        defaultChecked={isSelected}
      >
        {intl.messages['CLUB_MEMBER.CUSTOMER_PANEL_TEXT'].toString()}
      </MemberCheckbox>
    </div>
  );
};
