import React from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FormattedMessage } from 'react-intl';
import { classes } from './aph-tabs.styles';

type AphTab = {
  tabLabel: string;
  itemsCount?: number;
};

type AphTabsProps = {
  tabs: Array<AphTab>;
  ariaLabel?: string;
  tabIndex: number;
  /**
   * Use this prop to set the size of the tab buttons. small = 32px, medium = 40px, large = 48px
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large';
  onSelectTab: (tabIndex: number) => void;
  variant?: 'fullWidth' | 'standard';
};

export const AphTabs: React.FC<AphTabsProps> = ({
  tabs,
  tabIndex,
  ariaLabel,
  size = 'medium',
  onSelectTab,
  variant = 'standard',
}) => {
  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    onSelectTab(newValue);
  };

  return (
    <Tabs
      variant={variant}
      centered
      sx={[
        classes.tabs,
        size === 'large' && classes.activeLargeTab,
        size === 'small' && classes.activeSmallTab,
      ]}
      value={tabIndex}
      onChange={handleTabChange}
      aria-label={ariaLabel || ''}
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
    >
      {tabs.map(({ tabLabel, itemsCount }, i) => {
        const tabName = itemsCount ? (
          <FormattedMessage
            id="MY_PRESCRIPTIONS.PRESCRIPTION_LIST.TAB_LABEL"
            values={{ label: tabLabel, items: itemsCount }}
          />
        ) : (
          tabLabel
        );

        const prescriptionAriaLabel: string = itemsCount
          ? `${itemsCount} stycken ${tabLabel}`
          : tabLabel;

        return (
          <Tab
            data-testid="APH.TAB.ITEM"
            sx={[
              classes.tab,
              size === 'large' && classes.largeTab,
              size === 'small' && classes.smallTab,
            ]}
            disableRipple
            label={tabName}
            key={tabLabel}
            aria-controls={`tabpanel-${i}`}
            aria-label={prescriptionAriaLabel}
            id={`tab-${i}`}
          />
        );
      })}
    </Tabs>
  );
};
