import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { AphTabsContent } from '@aph/components/common/aph-tabs/aph-tabs-content.component';
import { AphTabs } from '@aph/components/common/aph-tabs/aph-tabs.component';
import { SubmitButtonWithStatesSkeleton } from '~/components/common';
import type { ConsentsState } from '~/model/gdpr-consent/consent-state';
import type { MemberConsentsState } from '~/model/gdpr-consent/use-member-consent-state';
import type { MemberState } from '~/model/member/member-state';
import { CustomerPanelSkeleton } from '../customer-panel/customer-panel.skeleton';
import { MemberDataInputSkeleton } from '../member-data-input/member-data-input.skeleton';
import { OffersSkeleton } from '../offers/offers.skeleton';
import { MyInfoConsentsContainer } from './my-info-consents/my-info-consents.container';
import { MyInfoPersonalContainer } from './my-info-personal/my-info-personal.container';

export type ActiveTabType = 0 | 1 | undefined;

type MyInfoComponentProps = {
  member: MemberState;
  memberConsents: MemberConsentsState;
  consents: ConsentsState;
  activeTab: ActiveTabType;
};

export const MyInfoComponent: React.FC<MyInfoComponentProps> = ({
  member,
  consents,
  memberConsents,
  activeTab,
}) => {
  const { data: memberData, loading: memberLoading, error: memberError } = member;
  const { consents: consentsData } = consents.data;
  const { loading: consentsLoading, error: consentsError } = consents;
  const { consents: memberConsentsData } = memberConsents.data;
  const { loading: memberConsentsLoading, error: memberConsentsError } = memberConsents;
  const intl = useIntl();

  const isLoadingMemberData = (memberLoading || !memberData) && !memberError;
  const isLoadingMemberConsents =
    (memberConsentsLoading || !memberConsentsData?.length) && !memberConsentsError;
  const isLoadingConsents = (consentsLoading || !consentsData?.length) && !consentsError;
  const shouldShowSkeleton = () =>
    activeTab === undefined || isLoadingMemberData || isLoadingMemberConsents || isLoadingConsents;

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(activeTab || 0);

  const onSelect = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };

  const tabs = [
    {
      tabLabel: intl.messages['CLUB_MEMBER.MY.INFO.TITLE'].toString(),
      tabContent: <MyInfoPersonalContainer />,
      id: '0',
    },
    {
      tabLabel: intl.messages['CLUB_MEMBER.CONSENT.TITLE'].toString(),
      tabContent: <MyInfoConsentsContainer />,
      id: '1',
    },
  ];

  if (shouldShowSkeleton()) {
    return (
      <div className="flex max-w-2xl flex-col gap-2" data-testid="MY.INFO.SKELETON">
        <div className="bg-action-subtle mb-4 h-9 w-full animate-pulse" />
        <MemberDataInputSkeleton />
        <OffersSkeleton />
        <CustomerPanelSkeleton />
        <SubmitButtonWithStatesSkeleton />
      </div>
    );
  }

  return (
    <div className="max-w-2xl">
      <div className="max-w-max">
        <AphTabs
          tabs={tabs}
          tabIndex={selectedTabIndex}
          ariaLabel="Mina uppgifter sid flikar"
          size="large"
          onSelectTab={onSelect}
        />
      </div>
      <AphTabsContent tabsContent={tabs} selectedTabIndex={selectedTabIndex} />
    </div>
  );
};
