import * as yup from 'yup';
import {
  hasSwedishLandCodeOrNoLandCode,
  isValidPhoneNumber,
} from '@aph/utilities/is-valid-phone-number';

export enum MemberInfoNames {
  STREET_NAME = 'streetName',
  MOBILENUMBER = 'mobileNumber',
  ZIP_CODE = 'zipCode',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MEMBERSHIP_NUMBER = 'membershipNumber',
  EMAIL = 'email',
  EMAILCONFIRM = 'emailConfirm',
  WANTS_OFFERS_BY_EMAIL = 'wantsOffersByEmail',
  WANTS_OFFERS_BY_SMS = 'wantsOffersBySms',
  WANTS_OFFERS_BY_MAIL = 'wantsOffersByMail',
  WANTS_TO_TAKE_PART_OF_CUSTOMER_PANELS = 'wantsToTakePartOfCustomerPanels',
  BMS_STORE_ID_OF_PREFERRED_PHARMACY = 'bmsStoreIdOfPreferredPharmacy',
  CITY = 'city',
  CONSENT_GENERAL_APPROVAL = 'consentGeneralApproval',
}

export interface CreateNewMemberFormValues
  extends NewMemberExplicitFormValues,
    SharedMemberFormValues {}
export interface MemberFormValues extends SharedMemberFormValues, UpdateMemberExplicitFormValues {}

type NewMemberExplicitFormValues = {
  [MemberInfoNames.CONSENT_GENERAL_APPROVAL]: boolean;
  [MemberInfoNames.EMAIL]: string;
  [MemberInfoNames.FIRST_NAME]: string;
  [MemberInfoNames.LAST_NAME]: string;
};

type UpdateMemberExplicitFormValues = {
  [MemberInfoNames.EMAIL]?: string;
};

type SharedMemberFormValues = {
  [MemberInfoNames.STREET_NAME]: string;
  [MemberInfoNames.MOBILENUMBER]?: string;
  [MemberInfoNames.ZIP_CODE]: number;
  [MemberInfoNames.MEMBERSHIP_NUMBER]: string;
  [MemberInfoNames.EMAILCONFIRM]?: string;
  [MemberInfoNames.WANTS_OFFERS_BY_EMAIL]: boolean;
  [MemberInfoNames.WANTS_OFFERS_BY_SMS]: boolean;
  [MemberInfoNames.WANTS_OFFERS_BY_MAIL]: boolean;
  [MemberInfoNames.WANTS_TO_TAKE_PART_OF_CUSTOMER_PANELS]: boolean;
  [MemberInfoNames.BMS_STORE_ID_OF_PREFERRED_PHARMACY]: number;
  [MemberInfoNames.CITY]?: string;
};

export const schema = yup.object().shape({
  streetName: yup
    .string()
    .required('COMMON.FORM_ELEMENTS.REQUIRED')
    .max(35, 'COMMON.FORM_ELEMENTS.INPUT_STREET.LENGTH'),
  mobileNumber: yup
    .string()
    .nullable()
    .test(
      'mobileNumber',
      'COMMON.FORM_ELEMENTS.INPUT_MOBILENUMBER.ERROR',
      (value) => value === undefined || value === null || value === '' || isValidPhoneNumber(value),
    )
    .test(
      'mobileNumber',
      'COMMON.FORM_ELEMENTS.INPUT_MOBILENUMBER.MUST_BE_SWEDISH',
      (value) => value === undefined || value === null || hasSwedishLandCodeOrNoLandCode(value),
    ),
  zipCode: yup
    .number()
    .required('COMMON.FORM_ELEMENTS.REQUIRED')
    .min(10000, 'COMMON.FORM_ELEMENTS.INPUT_POSTAL_CODE.ERROR')
    .max(99999, 'COMMON.FORM_ELEMENTS.INPUT_POSTAL_CODE.ERROR')
    .typeError('COMMON.FORM_ELEMENTS.INPUT_POSTAL_CODE.ERROR'),
  membershipNumber: yup.string(),
  email: yup
    .string()
    .required('COMMON.FORM_ELEMENTS.REQUIRED')
    .email('COMMON.FORM_ELEMENTS.INPUT_EMAIL.ERROR')
    .max(80, 'COMMON.FORM_ELEMENTS.INPUT_EMAIL.LENGTH'),
  emailConfirm: yup
    .string()
    .oneOf([yup.ref('email')], 'COMMON.FORM_ELEMENTS.INPUT_EMAIL_CONFIRM.ERROR'),
  wantsOffersByEmail: yup.boolean(),
  wantsOffersBySms: yup.boolean(),
  wantsOffersByMail: yup.boolean(),
  wantsToTakePartOfCustomerPanels: yup.boolean(),
  bmsStoreIdOfPreferredPharmacy: yup.string().required('COMMON.FORM_ELEMENTS.PHARMACY.REQUIRED'),
  city: yup
    .string()
    .required('COMMON.FORM_ELEMENTS.REQUIRED')
    .max(20, 'COMMON.FORM_ELEMENTS.INPUT_CITY.LENGTH'),
});

export const newMemberSchema = yup.object().shape({
  consentGeneralApproval: yup
    .boolean()
    .test(
      'consentGeneralApproval',
      'CLUB_MEMBER.REGISTER.CONSENT_GENERAL_REQUIRED',
      (x) => x === true,
    ),
  firstName: yup
    .string()
    .required('COMMON.FORM_ELEMENTS.REQUIRED')
    .max(15, 'COMMON.FORM_ELEMENTS.INPUT_FIRST_NAME.ERROR'),
  lastName: yup
    .string()
    .required('COMMON.FORM_ELEMENTS.REQUIRED')
    .max(27, 'COMMON.FORM_ELEMENTS.INPUT_LAST_NAME.ERROR'),
  ...schema.fields,
});
