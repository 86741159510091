import type { MemberDataV3 } from '~/services/generated/CustomerClient';
import type { MemberFormValues } from '../member-info.schema';

export function convertMemberDataToMemberFormState(data: MemberDataV3): MemberFormValues {
  return {
    bmsStoreIdOfPreferredPharmacy: data.bmsStoreIdOfPreferredPharmacy,
    city: data.city,
    email: data.email,
    emailConfirm: data.email,
    mobileNumber: data.mobileNumber,
    membershipNumber: data.membershipNumber ? data.membershipNumber : '',
    streetName: data.streetName ? data.streetName : '',
    zipCode: data.postalCode,
    wantsOffersByEmail: data.wantsOffersByEmail,
    wantsOffersByMail: data.wantsOffersByMail,
    wantsOffersBySms: data.wantsOffersBySms,
    wantsToTakePartOfCustomerPanels: data.wantsToTakePartOfCustomerPanels,
  };
}
