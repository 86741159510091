import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { SubmitButtonWithStates } from '@aph/components/common';
import { Alert } from '@aph/ui/components/alert/alert';
import { Typography } from '@aph/ui/components/typography/typography';
import { localizedErrorMessage } from '@aph/utilities/errors/errorLocalization';
import type { ErrorCode, ErrorIntlKey, ErrorMessage } from '@aph/utilities/errors/types';
import type { MemberState } from '~/model/member/member-state';
import type { UpdateMemberParams } from '~/model/member/update-member/update-member.api';
import { CustomerPanelContainer } from '../../customer-panel/customer-panel.container';
import { MemberDataDisabledContainer } from '../../member-data-disabled/member-data-disabled.container';
import { MemberDataInputContainer } from '../../member-data-input/member-data-input.container';
import type { MemberFormValues } from '../../member-info.schema';
import { schema } from '../../member-info.schema';
import { OffersContainer } from '../../offers/offers.container';
import { PharmacySelect } from '../../pharmacy-select/pharmacy-select.component';
import { convertMemberDataToMemberFormState } from '../my-info';

type MyInfoPersonalProps = {
  member: MemberState;
  resetSubmitEvent: () => void;
  updateMemberData: ({ body }: UpdateMemberParams) => void;
};

export const MyInfoPersonal: React.FC<MyInfoPersonalProps> = ({
  member,
  resetSubmitEvent,
  updateMemberData,
}) => {
  const intl = useIntl();

  const {
    success: memberSuccess,
    data: memberData,
    error: memberError,
    submitting: memberSubmitting,
    fetchFailed,
  } = member;

  const formStateMember = useForm<MemberFormValues>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const onSubmitMember: SubmitHandler<MemberFormValues> = (memberInfo) => {
    updateMemberData({ body: memberInfo });
    // clears errors from BE
    formStateMember.clearErrors();
  };

  const errorMessageServer = (error: ErrorIntlKey | (ErrorMessage & ErrorCode) | null) => {
    if (error) {
      const err = localizedErrorMessage(error, intl);
      if (err != null) {
        const errorMessage = { message: err };
        return errorMessage;
      }
    }
    return null;
  };

  return (
    <div data-testid="TAB.CONTENT.PERSONAL">
      <Typography typography="headingExtraLarge" className="mb-1 pt-3" asChild color="text-brand">
        <h1>
          <FormattedMessage id="CLUB_MEMBER.MY.INFO.TITLE" />
        </h1>
      </Typography>
      {memberError && fetchFailed && (
        <Alert
          data-pw="my-info-personal-alert"
          variant="error"
          title={localizedErrorMessage(memberError, intl) ?? undefined}
          data-testid="ERROR.ALERT"
        />
      )}
      {!!memberData && (
        <FormProvider {...formStateMember}>
          <form
            onSubmit={formStateMember.handleSubmit(onSubmitMember)}
            className="flex flex-col gap-2"
          >
            <MemberDataDisabledContainer
              firstName={memberData.firstName}
              lastName={memberData.lastName}
              membershipNumber={memberData.membershipNumber}
            />
            <MemberDataInputContainer
              streetName={memberData.streetName}
              zipCode={memberData.postalCode}
              mobileNumber={memberData.mobileNumber}
              email={memberData.email}
              city={memberData.city}
              memberError={memberError}
            />
            <OffersContainer
              wantsEmail={memberData.wantsOffersByEmail}
              wantsMail={memberData.wantsOffersByMail}
              wantsSms={memberData.wantsOffersBySms}
            />
            <PharmacySelect selectedIcaId={memberData.bmsStoreIdOfPreferredPharmacy} />
            <CustomerPanelContainer isSelected={memberData.wantsToTakePartOfCustomerPanels} />
            <SubmitButtonWithStates
              className="mb-3 items-start md:w-auto md:items-end lg:mb-0"
              buttonText={intl.formatMessage({ id: 'CLUB_MEMBER.MEMBER.SAVE' })}
              successButtonText={intl.formatMessage({ id: 'CLUB_MEMBER.MEMBER.SAVED' })}
              submitting={memberSubmitting}
              success={memberSuccess}
              error={errorMessageServer(memberError)}
              afterSuccessAction={() => {
                if (member.data) {
                  formStateMember.reset(convertMemberDataToMemberFormState(member.data));
                }
                resetSubmitEvent();
              }}
              afterSuccessActionDelay={
                process.env.NEXT_PUBLIC_DELAY_AFTER_SUCCESSFUL_FORM_SUBMIT as unknown as number
              }
            />
          </form>
        </FormProvider>
      )}
    </div>
  );
};
