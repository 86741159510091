export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const numberCheck = /^([+][^0])?(\d*[-|\s])*\d*$/;
  return numberCheck.test(phoneNumber) && phoneNumber.length > 7;
};

export const hasSwedishLandCodeOrNoLandCode = (phoneNumber: string): boolean => {
  const value = phoneNumber.trim();
  return (
    (value?.startsWith('00') ? value.startsWith('0046') : true) &&
    (value?.startsWith('+') ? value.startsWith('+46') : true)
  );
};
