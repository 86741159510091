import { container } from 'tsyringe';
import { logger } from '~/logging';
import type {
  CreateMemberRequestV3,
  ICreateMemberRequestV3,
  ICreateMemberResponseV3,
} from '~/services/generated/CustomerClient';
import { Client as CustomerClient } from '~/services/generated/CustomerClient';

export type CreateMemberParams = { body: ICreateMemberRequestV3 };

export const createMember = async (
  { body }: CreateMemberParams,
  correlationId: string,
): Promise<ICreateMemberResponseV3> => {
  const client = container.resolve(CustomerClient);
  const response = await client.createMemberV3(
    correlationId,
    'aphTinman',
    undefined,
    body as CreateMemberRequestV3,
  );
  logger.info(`Correlation ID: ${correlationId} createMember() was called`);
  return response;
};
