import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { logger } from '~/logging';
import { getActivePharmacies } from '../pharmacy.api';
import type { PharmaciesState } from './pharmacies-state';
import { initialState } from './pharmacies-state';

interface PharmaciesStore {
  pharmacies: PharmaciesState;
  actions: {
    getPharmacies: () => void;
  };
}

export const usePharmaciesStore = create<PharmaciesStore>()(
  devtools<PharmaciesStore>(
    (set) => ({
      pharmacies: initialState,
      actions: {
        getPharmacies: async () => {
          set(
            {
              pharmacies: {
                ...initialState,
                loading: true,
                error: false,
                updated: Date.now(),
              },
            },
            false,
            'Fetching pharmacies',
          );
          try {
            const response = await getActivePharmacies();
            logger.debug('All active pharmacies was fecthed successfully');
            set(
              {
                pharmacies: {
                  ...initialState,
                  data: response || initialState.data,
                  loading: false,
                  error: false,
                  updated: Date.now(),
                  success: true,
                },
              },
              false,
              'Fetching pharmacies success',
            );
          } catch (event) {
            const { message } = event as { message: string };
            const errorMessage = `getActivePharmacies: ${
              message || 'Failed to get active pharmacies data.'
            }`;

            logger.error(
              `getActivePharmacies failed to get new data | errorMessage: ${errorMessage}`,
            );

            set(
              {
                pharmacies: {
                  ...initialState,
                  loading: false,
                  error: true,
                  errorMessage: errorMessage || initialState.errorMessage,
                  updated: Date.now(),
                  success: false,
                },
              },
              false,
              'Fetching pharmacies failed',
            );
          }
        },
      },
    }),
    {
      name: 'UsePharmacies',
      anonymousActionType: 'UsePharmacies',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const usePharmaciesActions = () => usePharmaciesStore((state) => state.actions);
