import React from 'react';
import { MemberDataDisabled } from './member-data-disabled.component';

type MemberDataDisabledContainerProps = {
  firstName?: string;
  lastName?: string;
  membershipNumber?: string;
};

export const MemberDataDisabledContainer: React.FC<MemberDataDisabledContainerProps> = ({
  firstName,
  lastName,
  membershipNumber,
}) => (
  <MemberDataDisabled
    firstName={firstName}
    lastName={lastName}
    membershipNumber={membershipNumber}
  />
);
