import React from 'react';
import { CustomerPanel } from './customer-panel.component';

type CustomerPanelProps = {
  isSelected: boolean;
};

export const CustomerPanelContainer: React.FC<CustomerPanelProps> = ({ isSelected }) => (
  <CustomerPanel isSelected={isSelected} />
);
