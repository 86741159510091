import { container } from 'tsyringe';
import { logger } from '~/logging';
import type {
  IUpdateMemberRequestV3,
  UpdateMemberRequestV3,
  UpdateMemberResponseV3,
} from '~/services/generated/CustomerClient';
import { Client as CustomerClient } from '~/services/generated/CustomerClient';

export type UpdateMemberParams = { body: IUpdateMemberRequestV3 };

// Updates an existing member in Klubb Hjärtat.
export const updateMember = async (
  { body }: UpdateMemberParams,
  correlationId: string,
): Promise<UpdateMemberResponseV3> => {
  const client = container.resolve(CustomerClient);
  const response = await client.updateMemberV3(
    correlationId,
    'aphTinman',
    undefined,
    body as UpdateMemberRequestV3,
  );
  logger.info(`Correlation ID: ${correlationId} updateMember() was called`);
  return response;
};
