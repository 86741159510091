import React from 'react';
import Box from '@mui/material/Box';

type AphTabContent = {
  tabContent: JSX.Element;
  id: string;
};

type AphTabsContentProps = {
  tabsContent: Array<AphTabContent>;
  selectedTabIndex: number;
};

export const AphTabsContent: React.FC<AphTabsContentProps> = ({
  tabsContent,
  selectedTabIndex,
}) => {
  return (
    <>
      {tabsContent.map(({ tabContent, id }, i) => (
        <TabContent selectedTab={selectedTabIndex} index={i} key={id}>
          {tabContent}
        </TabContent>
      ))}
    </>
  );
};

type TabContentProps = {
  selectedTab: number;
  index: number;
  key: string;
  children?: React.ReactNode;
};

const TabContent = ({ children, selectedTab, index, ...other }: TabContentProps): JSX.Element => (
  <Box
    role="tabpanel"
    hidden={selectedTab !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {selectedTab === index && children}
  </Box>
);
