import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MemberInfoNames } from '../member-info.schema';
import { MemberInput } from '../member-input/member-input.component';

export type MemberDataDisabledProps = {
  firstName?: string;
  lastName?: string;
  membershipNumber?: string;
};

export const MemberDataDisabled: React.FC<MemberDataDisabledProps> = ({
  firstName,
  lastName,
  membershipNumber,
}) => {
  const intl = useIntl();
  const { register } = useFormContext();

  const { ref: firstNameRef, ...firstNameProps } = register(MemberInfoNames.FIRST_NAME);
  const { ref: lastNameRef, ...lastNameProps } = register(MemberInfoNames.LAST_NAME);
  const { ref: membershipRef, ...membershipProps } = register(MemberInfoNames.MEMBERSHIP_NUMBER);

  return (
    <div>
      <MemberInput
        id="COMMON.FORM_ELEMENTS.INPUT_FIRST_NAME.LABEL"
        label={intl.formatMessage({ id: 'COMMON.FORM_ELEMENTS.INPUT_FIRST_NAME.LABEL' })}
        inputRef={firstNameRef}
        defaultValue={firstName}
        className="mb-2 w-full sm:w-[49%]"
        disabled
        data-testid="MY.INFO.FIRST.NAME"
        {...firstNameProps}
      />
      <div className="flex flex-col justify-between sm:flex-row">
        <MemberInput
          id="COMMON.FORM_ELEMENTS.INPUT_LAST_NAME.LABEL"
          label={intl.formatMessage({ id: 'COMMON.FORM_ELEMENTS.INPUT_LAST_NAME.LABEL' })}
          inputRef={lastNameRef}
          defaultValue={lastName}
          className="mb-2 w-full sm:w-[49%]"
          disabled
          data-testid="MY.INFO.LAST.NAME"
          {...lastNameProps}
        />
        <MemberInput
          id="COMMON.FORM_ELEMENTS.INPUT_MEMBERSHIP_NUMBER.LABEL"
          label={intl.formatMessage({ id: 'COMMON.FORM_ELEMENTS.INPUT_MEMBERSHIP_NUMBER.LABEL' })}
          inputRef={membershipRef}
          defaultValue={membershipNumber}
          className="mb-2 w-full sm:w-[49%]"
          disabled
          data-testid="MY.INFO.MEMBERSHIP.NUMBER"
          {...membershipProps}
        />
      </div>
    </div>
  );
};
