import React, { useEffect, useState } from 'react';
import { useConsentsActions, useConsentsStore } from '~/model/gdpr-consent/use-consents-store';
import {
  useMemberConsentActions,
  useMemberConsentStore,
} from '~/model/gdpr-consent/use-member-consent-store';
import {
  useAmIMemberActions,
  useAmIMemberStore,
} from '~/model/member/am-i-member/use-am-i-member-store';
import { useMemberActions, useMemberStore } from '~/model/member/use-member-store';
import type { ActiveTabType } from './my-info.component';
import { MyInfoComponent } from './my-info.component';

export const MyInfoContainer: React.FC = () => {
  const { member } = useMemberStore();
  const { getMemberDataForMe } = useMemberActions();
  const { getMemberConsents } = useMemberConsentActions();
  const { memberConsents } = useMemberConsentStore();
  const { getConsents } = useConsentsActions();
  const { consents } = useConsentsStore();
  const { amIMember } = useAmIMemberStore();
  const { getAmIMember } = useAmIMemberActions();
  const [activeTab, setActiveTab] = useState<ActiveTabType>(undefined);

  useEffect(() => {
    if (amIMember.updated === 0) {
      getAmIMember();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (amIMember && amIMember.updated !== 0 && amIMember.data) {
      if (amIMember.data?.isMember && amIMember.data.consentsAreMissing) {
        // consents is missing and should be redirected to mypages and consents active
        setActiveTab(1);
      } else {
        setActiveTab(0);
      }
    }
  }, [amIMember]);

  useEffect(() => {
    if (member && member.updated === 0) {
      getMemberDataForMe();
    }
  }, [getMemberDataForMe, member]);

  useEffect(() => {
    if (memberConsents && memberConsents.updated === 0) {
      getMemberConsents();
    }
  }, [memberConsents, getMemberConsents]);

  useEffect(() => {
    if (consents && consents.updated === 0) {
      getConsents();
    }
  }, [consents, getConsents]);

  return (
    <MyInfoComponent
      member={member}
      consents={consents}
      memberConsents={memberConsents}
      activeTab={activeTab}
    />
  );
};
